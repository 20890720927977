var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-filter range-filter-dropdown" }, [
    _c("div", { staticClass: "dropdown keep-inside-clicks-open" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-filter",
          class: { applied: _vm.isApply },
          attrs: {
            id: _vm.filterId,
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _vm._v("\n            " + _vm._s(_vm.filterLabel) + "\n            "),
          _vm.isApply
            ? _c(
                "span",
                {
                  staticClass: "clear-icon",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    }
                  }
                },
                [_c("app-icon", { attrs: { name: "x" } })],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": _vm.filterId }
        },
        [
          _c("div", { staticClass: "btn-dropdown-close d-sm-none" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.filterLabel) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "back float-right",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeDropDown.apply(null, arguments)
                  }
                }
              },
              [_c("app-icon", { attrs: { name: "x" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-item pt-5" }, [
            _c("div", { attrs: { id: "slider-" + _vm.filterId } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "rate-status mt-primary d-flex justify-content-center"
              },
              [
                _c("div", { staticClass: "min-rate" }, [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.minTitle))
                  ]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        "" + _vm.sliderMinRange + (_vm.sign ? _vm.sign : "")
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "max-rate" }, [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.maxTitle))
                  ]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        "" + _vm.sliderMaxRange + (_vm.sign ? _vm.sign : "")
                      )
                    )
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider d-none d-sm-block" }),
          _vm._v(" "),
          _c("filter-action", {
            attrs: { "is-clear-disabled": _vm.clearFilterDisabled },
            on: { apply: _vm.applyFilter, clear: _vm.clear }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }