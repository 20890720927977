var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("form", { ref: "form" }, [
        _c(
          "div",
          {
            staticClass: "position-relative",
            class: { "loading-opacity": _vm.preloader }
          },
          [
            _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "d-block" }, [
                  _vm._v(_vm._s(_vm.$t("mail_subject")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-muted font-size-90" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "this_field_will_be_used_as_the_email_subject_and_identify_the_template"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: { required: true },
                  model: {
                    value: _vm.template.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.template, "subject", $$v)
                    },
                    expression: "template.subject"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("contents")))]),
                _vm._v(" "),
                _vm.loaded
                  ? _c("app-input", {
                      attrs: {
                        type: "text-editor",
                        required: true,
                        "text-editor-hints": _vm.textEditorHints(
                          Object.keys(_vm.tags)
                        ),
                        row: "5",
                        id: "text-editor-for-template"
                      },
                      model: {
                        value: _vm.template.content,
                        callback: function($$v) {
                          _vm.$set(_vm.template, "content", $$v)
                        },
                        expression: "template.content"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group text-center" },
              _vm._l(_vm.all_tags, function(tag) {
                return _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-primary px-3 py-1 margin-left-2 mt-2",
                    attrs: {
                      type: "button",
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: tag.description
                    },
                    on: {
                      click: function($event) {
                        return _vm.addTag(tag.tag)
                      }
                    }
                  },
                  [_vm._v(_vm._s(tag.tag))]
                )
              }),
              0
            )
          ],
          1
        )
      ])
    : _c("div", [_c("app-pre-loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }