var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.extended
      ? _c(
          "div",
          {
            staticClass: "avatar-group",
            class: _vm.avatarGroupClass,
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.extended = true
              }
            }
          },
          [
            _vm._l(_vm.avatars, function(avatar, index) {
              return index < 5
                ? [
                    avatar.img
                      ? _c("img", {
                          staticClass: "rounded-circle",
                          class: {
                            "avatar-bordered": _vm.border,
                            "avatar-shadow": _vm.shadow
                          },
                          attrs: {
                            src: avatar.img,
                            alt: avatar.alt ? avatar.alt : _vm.$t("not_found")
                          }
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "no-img rounded-circle",
                            class: {
                              "avatar-bordered": _vm.border,
                              "avatar-shadow": _vm.shadow
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("titleFilter")(
                                    avatar.title ? avatar.title : "AV"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        )
                  ]
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.avatars.length > 5
              ? _c("a", { staticClass: "pl-3 text-muted" }, [
                  _vm._v(
                    "\n            +" +
                      _vm._s(_vm.avatars.length - 5) +
                      " " +
                      _vm._s(_vm.$t("more")) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ],
          2
        )
      : _c("div", { staticClass: "row animate__animated animate__fadeIn" }, [
          _c("div", { staticClass: "col-12 d-flex justify-content-between" }, [
            _c(
              "div",
              { staticClass: "avatar-group-extended" },
              _vm._l(_vm.avatars, function(avatar, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "media d-flex align-items-center pt-2"
                  },
                  [
                    _c("div", { class: _vm.avatarGroupClass }, [
                      avatar.img
                        ? _c("img", {
                            staticClass: "rounded-circle",
                            class: {
                              "avatar-bordered": _vm.border,
                              "avatar-shadow": _vm.shadow
                            },
                            attrs: {
                              src: avatar.img,
                              alt: avatar.alt ? avatar.alt : _vm.$t("not_found")
                            }
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "no-img rounded-circle",
                              class: {
                                "avatar-bordered": _vm.border,
                                "avatar-shadow": _vm.shadow
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("titleFilter")(
                                      avatar.title ? avatar.title : "AV"
                                    )
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "media-body ml-3" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(avatar.title) +
                          "\n                        "
                      ),
                      _c("p", { staticClass: "text-muted font-size-90 mb-0" }, [
                        _vm._v(_vm._s(avatar.subTitle))
                      ])
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "mt-4",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.extended = false
                  }
                }
              },
              [_c("app-icon", { attrs: { name: "maximize-2" } })],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }