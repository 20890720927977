var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group-with-icon",
      class: { disabled: _vm.data.disabled }
    },
    [
      _c(
        "input",
        _vm._g(
          {
            class: [
              "form-control " + _vm.data.inputClass,
              { "text-right-padding": _vm.data.showPassword }
            ],
            attrs: {
              type: _vm.passwordFieldType,
              name: _vm.name,
              id: _vm.inputFieldId,
              required: _vm.data.required,
              placeholder: _vm.data.placeholder,
              readonly: _vm.data.readOnly,
              disabled: _vm.data.disabled,
              minlength: _vm.data.minlength,
              maxlength: _vm.data.maxlength,
              autocomplete: _vm.data.autocomplete
            },
            domProps: { value: _vm.value }
          },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _vm.data.showPassword
        ? _c(
            "div",
            {
              staticClass: "form-control-feedback cursor-pointer",
              class: { disabled: !_vm.value },
              attrs: {
                title:
                  _vm.passwordFieldType === "password"
                    ? _vm.$t("show_password")
                    : _vm.$t("hide_password")
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showPasswordText.apply(null, arguments)
                }
              }
            },
            [
              _vm.passwordFieldType === "password"
                ? _c(
                    "span",
                    {
                      key: _vm.passwordFieldType,
                      staticClass: "animate__animated animate__fadeIn"
                    },
                    [
                      _c("app-icon", {
                        staticClass: "size-19",
                        attrs: { name: "eye" }
                      })
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      key: _vm.passwordFieldType,
                      staticClass: "animate__animated animate__fadeIn"
                    },
                    [
                      _c("app-icon", {
                        staticClass: "size-19",
                        attrs: { name: "eye-off" }
                      })
                    ],
                    1
                  )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }