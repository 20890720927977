var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("app-table", {
        attrs: { id: _vm.tableId, options: _vm.options },
        on: { action: _vm.getListAction }
      }),
      _vm._v(" "),
      _vm.isAddEditModalActive
        ? _c("purchase-add-edit-modal", {
            attrs: {
              "table-id": _vm.tableId,
              "app-id": _vm.props.app_id,
              "selected-url": _vm.selectedUrl
            },
            on: { "close-modal": _vm.closeAddEditModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteConfirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "demo-delete" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }