var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        "modal-size": "default",
        "modal-alignment": "top",
        "modal-scroll": _vm.modalScroll,
        "modal-body-class": _vm.modalBodyClass
      },
      on: { "close-modal": _vm.closeModal }
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.closeModal.apply(null, arguments)
              }
            }
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        )
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [_vm._t("body")], 2),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c("div", { class: { "loading-opacity": _vm.preloader } }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary mr-2",
              attrs: { type: "button", "data-dismiss": "modal" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeModal.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("cancel")) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.$t("save")) + "\n            "
              )
            ]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }