var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100" }, [
    _c("div", { staticClass: "card border-0 bg-transparent" }, [
      _c(
        "div",
        { staticClass: "card-body p-0" },
        [
          _c("table-without-wrapper", {
            attrs: {
              options: _vm.options,
              id: _vm.id,
              columns: _vm.columns,
              "card-view": _vm.cardView,
              "filtered-data": _vm.filteredData,
              "search-value": _vm.searchValue,
              "clear-filter-visible": _vm.clearFilterVisible
            },
            on: {
              afterClearFilter: function($event) {
                return _vm.$emit("afterClearFilter")
              },
              action: _vm.getAction
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }