var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-steps" }, [
    _c(
      "ul",
      { staticClass: "nav nav-pills mb-4", attrs: { id: "pills-tab" } },
      _vm._l(_vm.tabs, function(tab, index) {
        return _c(
          "li",
          {
            key: "nav-link" + index,
            staticClass: "nav-item",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.disableTab(index)
              }
            }
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: {
                  active: index === _vm.currentTabIndex,
                  disabled: index > _vm.enableLimitIndex
                },
                attrs: {
                  id: "pills-" + tab.name + "-tab",
                  "data-toggle": "pill",
                  href: "#" + tab.name + "-" + index
                },
                on: {
                  click: function($event) {
                    return _vm.selectedTab(index)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-capitalize",
                    class: [index === 0 ? "tab-step-init" : "tab-step"]
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(tab.name) +
                        "\n                "
                    )
                  ]
                )
              ]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "tab-content pt-4",
        staticStyle: { "min-height": "200px" },
        attrs: { id: "pills-tabContent" }
      },
      _vm._l(_vm.tabs, function(tab, index) {
        return _c(
          "div",
          {
            key: "tab-pane" + index,
            staticClass: "tab-pane fade",
            class: { "show active": index === _vm.currentTabIndex },
            attrs: { id: tab.name + "-" + index }
          },
          [
            _c(tab.component, {
              tag: "component",
              attrs: { props: tab.props },
              on: { next: _vm.nextTab, back: _vm.prevTab }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }