var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.notificationTemplateModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
        "modal-body-class": "p-0"
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader
            ? _c("app-overlay-loader")
            : _c("app-tab", { attrs: { type: "horizontal", tabs: _vm.tabs } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }