var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-filter multi-select-filter search-filter-dropdown" },
    [
      _c(
        "div",
        { staticClass: "dropdown dropdown-with-animation chips-dropdown" },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-filter px-3",
              class: { applied: _vm.value.length },
              attrs: {
                href: "#",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              },
              on: { click: _vm.startNavigation }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.label ? _vm.label : _vm.$t("multi_select")) +
                  "\n            "
              ),
              _vm.value.length
                ? _c(
                    "span",
                    {
                      staticClass: "clear-icon",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearAndApply.apply(null, arguments)
                        }
                      }
                    },
                    [_c("app-icon", { attrs: { name: "x" } })],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu py-0",
              attrs: { "aria-labelledby": "dropdownMenuLink" }
            },
            [
              _c("div", { staticClass: "btn-dropdown-close d-sm-none" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.label ? _vm.label : _vm.$t("multi_select")) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "back float-right",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeDropDown.apply(null, arguments)
                      }
                    }
                  },
                  [_c("app-icon", { attrs: { name: "x" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group form-group-with-search" }, [
                _c(
                  "span",
                  { staticClass: "form-control-feedback" },
                  [_c("app-icon", { attrs: { name: "search" } })],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  ref: "searchInput",
                  class: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("search"),
                    autofocus: _vm.startNavigation
                  },
                  domProps: { value: _vm.searchValue },
                  on: {
                    input: function($event) {
                      return _vm.getSearchValue($event)
                    },
                    keydown: [
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown"
                          ])
                        ) {
                          return null
                        }
                        return _vm.navigateDown.apply(null, arguments)
                      },
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp"
                          ])
                        ) {
                          return null
                        }
                        return _vm.navigateUp.apply(null, arguments)
                      },
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.enterSelectedValue.apply(null, arguments)
                      }
                    ]
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-divider my-0" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "optionList",
                  staticClass: "dropdown-search-result-wrapper custom-scrollbar"
                },
                _vm._l(_vm.options, function(option, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      staticClass: "dropdown-item",
                      class: {
                        active: index == _vm.activeIndex,
                        selected: _vm.isChipSelected(option.id),
                        disabled: option.disabled
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addChips(option)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(option[_vm.listValueField]) +
                          "\n                    "
                      ),
                      _c(
                        "span",
                        { staticClass: "check-sign float-right" },
                        [
                          _c("app-icon", {
                            staticClass: "menu-icon",
                            attrs: { name: "check" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }