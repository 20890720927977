var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-filter avatars-filter" }, [
    _vm.list.length > 10
      ? _c("div", {
          staticClass: "indicator-left",
          attrs: { disabled: _vm.atHeadOfList },
          on: {
            click: function($event) {
              return _vm.moveCarousel(-1)
            }
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "avatar-group overflow-hidden" }, [
      _c(
        "div",
        {
          staticClass: "avatar-carousel-wrapper",
          style: {
            transform: "translateX" + "(" + _vm.currentOffset + "px" + ")"
          }
        },
        _vm._l(_vm.list, function(avatar, index) {
          return _c(
            "div",
            {
              staticClass: "position-relative d-inline-block single-avatar",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectedAvatar(avatar.id)
                }
              }
            },
            [
              _vm.getAvatarUrl(avatar)
                ? _c("img", {
                    staticClass: "rounded-circle avatar-bordered",
                    class: {
                      "active-avatar": avatar.id == _vm.selectedAvatarId
                    },
                    attrs: {
                      src: _vm.getAvatarUrl(avatar),
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: avatar[_vm.listValueField],
                      alt: avatar[_vm.listValueField]
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass: "no-img rounded-circle avatar-bordered",
                      class: {
                        "active-avatar": avatar.id == _vm.selectedAvatarId
                      },
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: avatar[_vm.listValueField]
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("titleFilter")(
                              avatar[_vm.listValueField]
                                ? avatar[_vm.listValueField]
                                : "AV"
                            )
                          ) +
                          "\n                "
                      )
                    ]
                  ),
              _vm._v(" "),
              _c("span", {
                staticClass: "status",
                class: "bg-" + avatar.status
              })
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _vm.list.length > 10
      ? _c("div", {
          staticClass: "indicator-right",
          attrs: { disabled: _vm.atEndOfList },
          on: {
            click: function($event) {
              return _vm.moveCarousel(1)
            }
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }