var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.userAndRoles.users.manageUserModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
        "modal-scroll": _vm.modalScroll
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader
            ? _c("app-overlay-loader")
            : [
                _vm._l(_vm.usersList, function(user, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                      class: {
                        "pb-3 mb-3 border-bottom":
                          index !== _vm.usersList.length - 1
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "media d-flex align-items-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "avatars-w-50" },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  title: user.full_name,
                                  shadow: true,
                                  img: _vm.$optional(
                                    user.profile_picture,
                                    "full_url"
                                  ),
                                  "alt-text": user.full_name
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "media-body ml-3" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(user.full_name) +
                                "\n                        "
                            ),
                            _c(
                              "p",
                              { staticClass: "text-muted font-size-90 mb-0" },
                              [_vm._v(_vm._s(user.email))]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass:
                              "d-flex align-items-center text-muted font-size-90",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.removeUser(user, index)
                              }
                            }
                          },
                          [
                            _c("app-icon", {
                              staticClass: "mr-1",
                              attrs: { name: "trash", width: "19" }
                            }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("remove")) +
                                "\n                    "
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    ref: "form",
                    staticClass: "mb-0",
                    attrs: {
                      "data-url":
                        "admin/auth/roles/" +
                        _vm.userAndRoles.rowData.id +
                        "/attach-users"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "form",
                        staticClass: "mt-primary",
                        attrs: { "data-url": "attach-user" }
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "multi-select",
                            list: _vm.attachableUsers,
                            "list-value-field": "full_name",
                            required: true
                          },
                          model: {
                            value: _vm.selectedUsers,
                            callback: function($$v) {
                              _vm.selectedUsers = $$v
                            },
                            expression: "selectedUsers"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }