var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-search-select" }, [
    _c(
      "div",
      {
        staticClass: "search-filter-dropdown",
        class: { disabled: _vm.data.disabled }
      },
      [
        _c(
          "div",
          {
            staticClass: "dropdown dropdown-with-animation",
            class: { "chips-dropdown": _vm.data.isAnimatedDropdown }
          },
          [
            _c(
              "div",
              {
                staticClass: "p-2 chips-container custom-scrollbar",
                attrs: { id: "dropdownMenuLink", "data-toggle": "dropdown" },
                on: { click: _vm.startNavigation }
              },
              [
                _vm.data.multiCreatePreloader
                  ? _c("app-overlay-loader")
                  : [
                      _vm._l(_vm.selectedOptions, function(chip, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass:
                              "chips d-inline-flex align-items-center justify-content-center mr-2 mb-2"
                          },
                          [
                            _c("span", { staticClass: "mr-3" }, [
                              _vm._v(_vm._s(chip[_vm.data.listValueField]))
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "delete-chips d-inline-flex align-items-center justify-content-center",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteChips($event, chip.id)
                                  }
                                }
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "size-14",
                                  attrs: { name: "x" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "add" }, [
                        _vm._v(
                          "+ " +
                            _vm._s(
                              _vm.data.placeholder
                                ? _vm.data.placeholder
                                : _vm.$t("add")
                            )
                        )
                      ])
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dropdown-menu chips-dropdown-menu py-0",
                class: _vm.data.listClass,
                attrs: { "aria-labelledby": "dropdownMenuLink" }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group form-group-with-search" },
                  [
                    _c(
                      "span",
                      { staticClass: "form-control-feedback" },
                      [_c("app-icon", { attrs: { name: "search" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchValue,
                          expression: "searchValue"
                        }
                      ],
                      ref: "searchInput",
                      class: "form-control " + _vm.data.listItemInputClass,
                      attrs: { type: "text", autofocus: _vm.startNavigation },
                      domProps: { value: _vm.searchValue },
                      on: {
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.navigateUp.apply(null, arguments)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.navigateDown.apply(null, arguments)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.enterSelectedValue.apply(null, arguments)
                          }
                        ],
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchValue = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider my-0" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "optionList",
                    staticClass:
                      "dropdown-search-result-wrapper custom-scrollbar"
                  },
                  [
                    _vm._l(_vm.options, function(option, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "dropdown-item",
                          class: {
                            active: index === _vm.activeIndex,
                            "disabled selected": _vm.isChipSelected(option.id),
                            disabled: option.disabled
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addChips(option)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option[_vm.data.listValueField]) +
                              "\n                        "
                          ),
                          _c(
                            "span",
                            { staticClass: "check-sign float-right" },
                            [
                              _c("app-icon", {
                                staticClass: "menu-icon",
                                attrs: { name: "check" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.options.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-muted text-size-13 py-primary"
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.hintText) }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }