var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl
                  ? "purchase-keys/" + _vm.inputs.id
                  : "purchase-keys"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_purchase_key" }
                    },
                    [_vm._v(_vm._s(_vm.$t("purchase_key")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("purchase_key"),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.purchase_key,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "purchase_key", $$v)
                      },
                      expression: "inputs.purchase_key"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_domain_name" }
                    },
                    [_vm._v(_vm._s(_vm.$t("domain_name")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("app_domain_name"),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.domain_name,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "domain_name", $$v)
                      },
                      expression: "inputs.domain_name"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }