var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.componentLoader
    ? _c(
        "div",
        { staticClass: "position-relative h-100" },
        [_c("app-overlay-loader")],
        1
      )
    : _c("div", [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: {
              "data-url":
                "admin/auth/users/" +
                _vm.user.loggedInUser.id +
                "/password/change"
            }
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-lg-3 col-xl-3 d-flex align-items-center"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                        attrs: { for: "user_old_password" }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("old_password")) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "user_old_password",
                        type: "password",
                        placeholder: _vm.$t("type_your_current_password"),
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "old_password"
                        ),
                        required: true
                      },
                      model: {
                        value: _vm.authUser.old_password,
                        callback: function($$v) {
                          _vm.$set(_vm.authUser, "old_password", $$v)
                        },
                        expression: "authUser.old_password"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-3 col-xl-3" }, [
                  _c(
                    "label",
                    {
                      staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                      attrs: { for: "user_password" }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("new_password")) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "user_password",
                        type: "password",
                        placeholder: _vm.$t("type_your_new_password"),
                        specialValidation: true,
                        required: true
                      },
                      model: {
                        value: _vm.authUser.password,
                        callback: function($$v) {
                          _vm.$set(_vm.authUser, "password", $$v)
                        },
                        expression: "authUser.password"
                      }
                    }),
                    _vm._v(" "),
                    _c("PasswordWarning")
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-lg-3 col-xl-3 d-flex align-items-center"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                        attrs: { for: "user_password_confirmation" }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("conform_password")) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "user_password_confirmation",
                        type: "password",
                        placeholder: _vm.$t("re_type_your_new_password"),
                        "same-as": "authUser_password",
                        required: true
                      },
                      model: {
                        value: _vm.authUser.password_confirmation,
                        callback: function($$v) {
                          _vm.$set(_vm.authUser, "password_confirmation", $$v)
                        },
                        expression: "authUser.password_confirmation"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-0 mt-5" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-3",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("save")) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }