var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.userAndRoles.roles.addEditModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl
                  ? "admin/auth/roles/" + _vm.userAndRoles.rowData.id
                  : "admin/auth/roles"
              }
            },
            [
              _vm.userAndRoles.roles.addEditModalTitle == "role"
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("role_name")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("enter_a_role_name"),
                          required: true
                        },
                        model: {
                          value: _vm.inputs.name,
                          callback: function($$v) {
                            _vm.$set(_vm.inputs, "name", $$v)
                          },
                          expression: "inputs.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.preloader
                ? _c("div", { staticClass: "form-group mb-0" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("permission")))]),
                    _vm._v(" "),
                    _vm.groupLoaded
                      ? _c(
                          "div",
                          {
                            staticClass: "accordion",
                            attrs: { id: "accordionExample" }
                          },
                          _vm._l(
                            Object.keys(_vm.userAndRoles.permissionList),
                            function(permission, index) {
                              return _c(
                                "div",
                                {
                                  key: "permission-item-" + index,
                                  staticClass: "card"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-header border-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer",
                                          attrs: {
                                            "data-toggle": "collapse",
                                            "data-target": "#" + permission,
                                            "aria-expanded": "false",
                                            "aria-expanded":
                                              "" +
                                              (_vm.checkForVisibility(
                                                index,
                                                permission
                                              )
                                                ? true
                                                : false),
                                            "aria-controls": "permission"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "customized-checkbox checkbox-default"
                                            },
                                            [
                                              _c("input", {
                                                ref: "checkbox",
                                                refInFor: true,
                                                attrs: {
                                                  type: "checkbox",
                                                  name:
                                                    "single-checkbox-" +
                                                    permission,
                                                  id:
                                                    "single-checkbox-" +
                                                    permission
                                                },
                                                domProps: {
                                                  value: permission,
                                                  checked:
                                                    _vm.isCheckedCheckbox[
                                                      permission
                                                    ]
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation(),
                                                      _vm.groupCheck(
                                                        $event,
                                                        permission
                                                      )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    for:
                                                      "single-checkbox-" +
                                                      permission
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(permission)
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "collapse " +
                                        (_vm.checkForVisibility(
                                          index,
                                          permission
                                        )
                                          ? "show"
                                          : ""),
                                      attrs: {
                                        id: permission,
                                        "data-parent": "#accordionExample"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-body p-primary" },
                                        [
                                          _vm.subGroupLoaded
                                            ? _c("app-input", {
                                                attrs: {
                                                  type: "checkbox",
                                                  list:
                                                    _vm.userAndRoles
                                                      .permissionList[
                                                      permission
                                                    ],
                                                  "list-value-field":
                                                    "translated_name"
                                                },
                                                on: {
                                                  changed: function($event) {
                                                    return _vm.checkPermissions(
                                                      $event,
                                                      permission
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkedPermissions[
                                                      permission
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.checkedPermissions,
                                                      permission,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "checkedPermissions[permission]"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }