var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-navbar", {
    attrs: {
      "logo-url": _vm.logo,
      user: _vm.user.loggedInUser,
      "selected-language": _vm.userLanguage,
      "language-data": _vm.languageList,
      notificationData: _vm.notificationList,
      "profile-data": _vm.profileData,
      showIdentifier: _vm.showIdentifier,
      "all-notification-url": "/all-notifications"
    },
    on: { clicked: _vm.readNotification }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }