var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "row justify-content-center align-items-center h-100 white-base-color"
    },
    [
      _c("div", { staticClass: "col-12 col-sm-9 col-md-7 col-lg-5 col-xl-6" }, [
        _c("div", { staticClass: "text-center" }, [
          _vm.errorType === "400"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-400.png"), alt: "" }
              })
            : _vm.errorType === "401"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-401.png"), alt: "" }
              })
            : _vm.errorType === "403"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-403.png"), alt: "" }
              })
            : _vm.errorType === "404"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-404.png"), alt: "" }
              })
            : _vm.errorType === "500"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-500.png"), alt: "" }
              })
            : _vm.errorType === "503"
            ? _c("img", {
                staticClass: "img-fluid d-block mx-auto",
                attrs: { src: _vm.imagesPath("images/error-503.png"), alt: "" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h5", { staticClass: "my-4" }, [
            _vm._v(_vm._s(_vm.errorType) + " - " + _vm._s(_vm.errorTitle))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-muted mb-4" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.errorDescription) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-primary", attrs: { href: _vm.url } },
            [_vm._v(_vm._s(_vm.$t("go_back_to_your_page")))]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }