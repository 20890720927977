var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card card-with-shadow dashboard-widgets dashboard-icon-widget p-primary border-0"
    },
    [
      _c(
        "div",
        { staticClass: "icon-wrapper" },
        [_c("app-icon", { attrs: { name: _vm.data.icon } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "widget-data" }, [
        _c("h6", [_vm._v(_vm._s(_vm.data.number))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.data.label))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }