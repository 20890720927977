var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
        "modal-scroll": false
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl
                  ? "application-release/" + _vm.inputs.id
                  : "application-release"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    { staticClass: "col-sm-3 mb-0", attrs: { for: "version" } },
                    [_vm._v(_vm._s(_vm.$t("version")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "version",
                      type: "text",
                      placeholder: _vm.$t("version"),
                      required: true,
                      "error-message": _vm.$errorMessage(_vm.errors, "version")
                    },
                    model: {
                      value: _vm.inputs.version,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "version", $$v)
                      },
                      expression: "inputs.version"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_upload_file" }
                    },
                    [_vm._v(_vm._s(_vm.$t("upload_file")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_upload_file",
                      type: "custom-file-upload",
                      label: _vm.$t("zip_only"),
                      "error-message": _vm.$errorMessage(
                        _vm.errors,
                        "zip_file_reference"
                      ),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.zip_file_reference,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "zip_file_reference", $$v)
                      },
                      expression: "inputs.zip_file_reference"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "release_date" }
                    },
                    [_vm._v(_vm._s(_vm.$t("release_date")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "release_date",
                      "min-date": _vm.inputs.release_date,
                      type: "date",
                      "error-message": _vm.$errorMessage(
                        _vm.errors,
                        "release_date"
                      ),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.release_date,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "release_date", $$v)
                      },
                      expression: "inputs.release_date"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }