var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl
                  ? "applications/" + _vm.inputs.id
                  : "applications"
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_app_id" }
                    },
                    [_vm._v(_vm._s(_vm.$t("app_name")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("app_name"),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.app_name,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "app_name", $$v)
                      },
                      expression: "inputs.app_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_app_id" }
                    },
                    [_vm._v(_vm._s(_vm.$t("app_id")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("app_id"),
                      required: true
                    },
                    model: {
                      value: _vm.inputs.app_id,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "app_id", $$v)
                      },
                      expression: "inputs.app_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "code_canyon_item_id" }
                    },
                    [_vm._v(_vm._s(_vm.$t("code_canyon_item_id")))]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "code_canyon_item_id",
                      type: "text",
                      placeholder: _vm.$t("code_canyon_item_id")
                    },
                    model: {
                      value: _vm.inputs.code_canyon_item_id,
                      callback: function($$v) {
                        _vm.$set(_vm.inputs, "code_canyon_item_id", $$v)
                      },
                      expression: "inputs.code_canyon_item_id"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }