var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-with-shadow border-0 h-100" }, [
    _c(
      "div",
      {
        staticClass:
          "card-header d-flex align-items-center p-primary primary-card-color"
      },
      [
        _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
          _vm._v(_vm._s(_vm.$t("roles")))
        ]),
        _vm._v(" "),
        _c("app-search", { on: { input: _vm.getSearchValue } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body p-0" },
      [
        _c("app-table", {
          attrs: {
            id: _vm.data.tableId,
            search: _vm.search,
            options: _vm.rolesTableOptions
          },
          on: { action: _vm.action }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }