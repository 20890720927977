var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Chart" },
    [
      _c("chart-bar", {
        attrs: {
          data: _vm.data,
          height: _vm.data.height,
          width: _vm.data.width,
          "dark-mode": _vm.darkMode
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }