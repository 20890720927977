var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-note", {
    staticClass: "mt-2",
    attrs: {
      "note-type": "note-warning",
      "show-title": false,
      notes: [_vm.$t("password_hint_note")]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }