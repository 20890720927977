var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vertical-tab" }, [
    _c("div", { staticClass: "row no-gutters" }, [
      _c("div", { staticClass: "col-md-3 pr-md-3 tab-menu" }, [
        _c("div", { staticClass: "card card-with-shadow border-0" }, [
          _c("div", { staticClass: "header-icon" }, [
            _c(
              "div",
              { staticClass: "icon-position d-flex justify-content-center" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tab-icon d-flex justify-content-center align-items-center"
                  },
                  [_c("app-icon", { attrs: { name: _vm.icon } })],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "px-primary py-primary" }, [
            _c(
              "div",
              {
                staticClass: "nav flex-column nav-pills",
                attrs: { role: "tablist", "aria-orientation": "vertical" }
              },
              _vm._l(_vm.filteredTab, function(tab, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass:
                      "text-capitalize tab-item-link d-flex justify-content-between my-2 my-sm-3",
                    class: { active: index == _vm.currentIndex },
                    attrs: {
                      id: "v-pills-" + tab.name + "-tab",
                      "data-toggle": "pill",
                      href: "#" + tab.name + "-" + index
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.loadComponent(tab, index)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(tab.name))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "active-icon" },
                      [_c("app-icon", { attrs: { name: "chevron-right" } })],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-9 pl-md-3 pt-md-0 pt-sm-4 pt-4" }, [
        _c("div", { staticClass: "card card-with-shadow border-0" }, [
          _c("div", { staticClass: "tab-content px-primary" }, [
            _c(
              "div",
              {
                staticClass: "tab-pane fade active show",
                attrs: { id: _vm.componentId }
              },
              [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "d-flex align-items-center text-capitalize mb-0 title tab-content-header"
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.componentTitle)
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mb-0" }, [
                    !_vm.isUndefined(_vm.componentButton.label)
                      ? _c(
                          "button",
                          {
                            class: _vm.componentButton.class
                              ? _vm.componentButton.class
                              : "btn btn-primary",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.headerBtnClicked.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.componentButton.label) +
                                "\n                                "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content py-primary" },
                  [
                    _c(_vm.componentName, {
                      tag: "component",
                      attrs: { props: _vm.componentProps, id: _vm.componentId }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }