var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-tel-input", {
    attrs: {
      id: _vm.inputFieldId,
      type: "text",
      value: _vm.value,
      required: _vm.data.required,
      mode: "international",
      disabled: _vm.data.disabled,
      name: _vm.name,
      placeholder: _vm.data.placeholder,
      inputClasses: _vm.data.inputClass + "default-font-color"
    },
    on: { input: _vm.input }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }