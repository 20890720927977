import { render, staticRenderFns } from "./GroupOfUsers.vue?vue&type=template&id=f4bbe800&"
import script from "./GroupOfUsers.vue?vue&type=script&lang=js&"
export * from "./GroupOfUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/Gain.Marketplace/Gain.Marketplace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f4bbe800')) {
      api.createRecord('f4bbe800', component.options)
    } else {
      api.reload('f4bbe800', component.options)
    }
    module.hot.accept("./GroupOfUsers.vue?vue&type=template&id=f4bbe800&", function () {
      api.rerender('f4bbe800', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/app/components/views/user-and-roles/roles/GroupOfUsers.vue"
export default component.exports