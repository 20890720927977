var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-md-10 col-lg-8" }, [
      _c(
        "div",
        { staticClass: "card card-with-shadow border-0 my-primary" },
        [
          _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
            _c("h5", { staticClass: "card-title m-0" }, [
              _vm._v(_vm._s(_vm.$t("debug")))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body position-relative" },
            [
              _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  ref: "form",
                  staticClass: "mb-0",
                  class: { "loading-opacity": _vm.preloader },
                  attrs: { "data-url": "/verify-envato/" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-2 mb-sm-0",
                          attrs: { for: "text_input" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("purchase_key")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("app-input", {
                        staticClass: "col-sm-10",
                        attrs: {
                          id: "text_input",
                          type: "text",
                          placeholder: _vm.$t("purchase_key")
                        },
                        model: {
                          value: _vm.inputs.purchase_key,
                          callback: function($$v) {
                            _vm.$set(_vm.inputs, "purchase_key", $$v)
                          },
                          expression: "inputs.purchase_key"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-5 action-buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-2",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("submit")) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.clear.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("clear")) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.noteActive
            ? [
                _c(
                  "div",
                  { staticClass: "px-primary" },
                  [
                    _c("app-note", {
                      staticClass: "mb-primary",
                      attrs: {
                        title: _vm.$t("note"),
                        "show-title": false,
                        "content-type": "html",
                        notes: _vm.responseNote
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }