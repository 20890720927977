var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center justify-content-center" },
    [
      _c(
        "div",
        {
          staticClass: "spinner-border",
          class: _vm.spinnerClass,
          attrs: { role: "status" }
        },
        [_c("span", { staticClass: "sr-only" })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }