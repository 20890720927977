var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8" },
        [
          _c("div", {
            staticClass: "back-image",
            style:
              "background-image: url(" + _vm.configData.company_banner + ")"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pl-md-0" },
        [
          _c("div", { staticClass: "login-form d-flex align-items-center" }, [
            _c(
              "form",
              {
                ref: "form",
                staticClass: "sign-in-sign-up-form w-100",
                attrs: { "data-url": "/user/reset-password", action: "store" }
              },
              [
                _c("div", { staticClass: "text-center mb-4" }, [
                  _c("img", {
                    staticClass: "img-fluid logo",
                    attrs: {
                      src: _vm.configData.company_logo
                        ? _vm.configData.company_logo
                        : "/images/core.png",
                      alt: ""
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-12" }, [
                    _c("h6", { staticClass: "text-center mb-0" }, [
                      _vm._v(_vm._s(_vm.$t("reset_password")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12" },
                    [
                      _c("label", { attrs: { for: "formData_password" } }, [
                        _vm._v(_vm._s(_vm.$t("new_password")))
                      ]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "password",
                          specialValidation: true,
                          placeholder: _vm.$t("enter_your_password"),
                          required: true
                        },
                        model: {
                          value: _vm.formData.password,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "password", $$v)
                          },
                          expression: "formData.password"
                        }
                      }),
                      _vm._v(" "),
                      _c("PasswordWarning")
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12" },
                    [
                      _c(
                        "label",
                        { attrs: { for: "formData_password_confirmation" } },
                        [_vm._v(_vm._s(_vm.$t("confirm_password")))]
                      ),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "password",
                          "same-as": "formData_password",
                          placeholder: _vm.$t("confirm_password"),
                          required: true
                        },
                        model: {
                          value: _vm.formData.password_confirmation,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "password_confirmation", $$v)
                          },
                          expression: "formData.password_confirmation"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12" },
                    [
                      _c("app-load-more", {
                        class: { disabled: !_vm.user },
                        attrs: {
                          preloader: _vm.preloader,
                          label: _vm.$t("submit"),
                          type: "submit",
                          "class-name": "btn btn-primary btn-block text-center"
                        },
                        on: { submit: _vm.submit }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("p", { staticClass: "text-center mt-5" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t("copyright_text") +
                              _vm.configData.company_name
                          ) +
                          "\n                            "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }