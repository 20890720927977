var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _vm.countCreatedResponse < 3
      ? _c(
          "div",
          {
            staticClass:
              "root-preloader position-absolute overlay-loader-wrapper"
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("app-breadcrumb", {
          attrs: {
            "page-title": _vm.$t("default"),
            directory: _vm.$t("dashboard"),
            icon: "pie-chart"
          }
        }),
        _vm._v(" "),
        !_vm.defaultData && !_vm.mainPreloader
          ? _c("app-overlay-loader")
          : _vm._e(),
        _vm._v(" "),
        _vm.defaultData
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.info.getDefaultDashboardInfo.topSectionData, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"
                  },
                  [
                    _c("app-widget", {
                      staticClass: "mb-primary",
                      attrs: {
                        type: "app-widget-with-icon",
                        label: item.label,
                        number: _vm.numberFormat(item.number),
                        icon: item.icon
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 mb-primary"
            },
            [
              !_vm.browserChart && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.browserChart
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center"
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("request_overview")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "line-chart",
                              height: 210,
                              labels: _vm.info.requestOverView.labels,
                              "data-sets": _vm.info.requestOverView.chartData
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-primary"
            },
            [
              !_vm.isActiveDoughnutData && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveDoughnutData
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center"
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("request_status")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "dough-chart",
                              height: 230,
                              labels: _vm.info.doughnutData.labels,
                              "data-sets": _vm.info.doughnutData.dataSet
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "chart-data-list" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(
                                _vm.info.doughnutData.chartElement,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "col-12" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "data-group-item",
                                          style: item.color
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "square",
                                            style: item.background_color
                                          }),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.key) +
                                              "\n                                        "
                                          ),
                                          _c("span", { staticClass: "value" }, [
                                            _vm._v(_vm._s(item.value))
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center"
      },
      [
        _c("span", { staticClass: "bounce1 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce4" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }