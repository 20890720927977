import { render, staticRenderFns } from "./UserModal.vue?vue&type=template&id=36607b8f&"
import script from "./UserModal.vue?vue&type=script&lang=js&"
export * from "./UserModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/Gain.Marketplace/Gain.Marketplace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36607b8f')) {
      api.createRecord('36607b8f', component.options)
    } else {
      api.reload('36607b8f', component.options)
    }
    module.hot.accept("./UserModal.vue?vue&type=template&id=36607b8f&", function () {
      api.rerender('36607b8f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/app/components/views/user-and-roles/users/UserModal.vue"
export default component.exports