var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": "/admin/app/settings",
                enctype: "multipart/form-data"
              }
            },
            [
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3"
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("company_info")) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsCompanyName" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_name")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCompanyName",
                              type: "text",
                              placeholder: _vm.$t("type_your_company_name"),
                              required: true
                            },
                            model: {
                              value: _vm.appSettings.company_name,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "company_name", $$v)
                              },
                              expression: "appSettings.company_name"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 col-form-label h-fit-content",
                          attrs: { for: "appSettingsCompanyLogo" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_logo"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("recommended_company_logo_size")
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCompanyLogo",
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_logo")
                            },
                            model: {
                              value: _vm.appSettings.company_logo,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "company_logo", $$v)
                              },
                              expression: "appSettings.company_logo"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 col-form-label h-fit-content",
                          attrs: { for: "appSettingsCompanyIcon" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_icon"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("recommended_company_icon_size")
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCompanyIcon",
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_icon")
                            },
                            model: {
                              value: _vm.appSettings.company_icon,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "company_icon", $$v)
                              },
                              expression: "appSettings.company_icon"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 col-form-label h-fit-content",
                          attrs: { for: "appSettingsCompanyBanner" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("company_banner"))
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted font-italic" },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t("recommended_company_banner_size")
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCompanyBanner",
                              type: "custom-file-upload",
                              "generate-file-url": false,
                              label: _vm.$t("change_banner")
                            },
                            model: {
                              value: _vm.appSettings.company_banner,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "company_banner", $$v)
                              },
                              expression: "appSettings.company_banner"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsLanguage" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("language")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsLanguage",
                              type: "select",
                              list: _vm.languageList,
                              required: true
                            },
                            model: {
                              value: _vm.appSettings.language,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "language", $$v)
                              },
                              expression: "appSettings.language"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3"
                    },
                    [_vm._v(_vm._s(_vm.$t("date_and_time_setting")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsDateFormat" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("date_format")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsDateFormat",
                              type: "select",
                              list: _vm.dateFormats
                            },
                            model: {
                              value: _vm.appSettings.date_format,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "date_format", $$v)
                              },
                              expression: "appSettings.date_format"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsTimeFormat" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("time_format")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsTimeFormat",
                              type: "radio-buttons",
                              list: _vm.timeFormats
                            },
                            model: {
                              value: _vm.appSettings.time_format,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "time_format", $$v)
                              },
                              expression: "appSettings.time_format"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsTimeZone" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("time_zone")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsTimeZone",
                              type: "select",
                              list: _vm.timeZones
                            },
                            model: {
                              value: _vm.appSettings.time_zone,
                              callback: function($$v) {
                                _vm.$set(_vm.appSettings, "time_zone", $$v)
                              },
                              expression: "appSettings.time_zone"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "form-group mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "legend",
                    {
                      staticClass:
                        "col-12 col-form-label text-primary pt-0 mb-3"
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("currency_setting")) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsCurrencySymbol" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("currency_symbol")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCurrencySymbol",
                              type: "text",
                              required: true
                            },
                            model: {
                              value: _vm.appSettings.currency_symbol,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "currency_symbol",
                                  $$v
                                )
                              },
                              expression: "appSettings.currency_symbol"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsDecimalSeparator" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("decimal_separator")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsDecimalSeparator",
                              type: "radio-buttons",
                              list: _vm.decimalSeparators,
                              required: true
                            },
                            on: {
                              input: function($event) {
                                return _vm.changeValue("decimal_separator")
                              }
                            },
                            model: {
                              value: _vm.appSettings.decimal_separator,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "decimal_separator",
                                  $$v
                                )
                              },
                              expression: "appSettings.decimal_separator"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsThousandSeparator" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("thousand_separator")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsThousandSeparator",
                              type: "radio-buttons",
                              list: _vm.thousandSeparators,
                              required: true
                            },
                            on: {
                              input: function($event) {
                                return _vm.changeValue("thousand_separator")
                              }
                            },
                            model: {
                              value: _vm.appSettings.thousand_separator,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "thousand_separator",
                                  $$v
                                )
                              },
                              expression: "appSettings.thousand_separator"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsNumberOfDecimal" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("number_of_decimal")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsNumberOfDecimal",
                              type: "radio-buttons",
                              list: _vm.numberOfDecimals,
                              required: true
                            },
                            model: {
                              value: _vm.appSettings.number_of_decimal,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "number_of_decimal",
                                  $$v
                                )
                              },
                              expression: "appSettings.number_of_decimal"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-lg-3 col-xl-3 col-form-label",
                          attrs: { for: "appSettingsCurrencyPosition" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("currency_position")) +
                              "\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "appSettingsCurrencyPosition",
                              type: "radio-buttons",
                              list: _vm.currencyPositions,
                              required: true
                            },
                            model: {
                              value: _vm.appSettings.currency_position,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appSettings,
                                  "currency_position",
                                  $$v
                                )
                              },
                              expression: "appSettings.currency_position"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-5" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("save")) +
                        "\n            "
                    )
                  ]
                )
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }