var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-end p-0" },
        [_c("app-search", { on: { input: _vm.getSearchValue } })],
        1
      ),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: _vm.tableId, search: _vm.search, options: _vm.option },
        on: { action: _vm.getActionFromSettings }
      }),
      _vm._v(" "),
      _vm.isActive
        ? _c("notification-settings-update-modal", {
            attrs: { "selected-url": _vm.selectedUrl },
            on: { "close-modal": _vm.closeModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isActiveTemplateModal
        ? _c("notification-tamplate-update-modal", {
            attrs: { "selected-url": _vm.selectedUrl, eventId: _vm.eventId },
            on: { "close-modal": _vm.closeTemplateModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }