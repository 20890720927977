var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav mb-primary tab-filter-menu" },
    _vm._l(_vm.filterOption, function(item, index) {
      return _c("li", { key: index, staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link py-0",
            class: [
              _vm.value == item.id
                ? "active"
                : index === 0 && _vm.value === ""
                ? "active"
                : ""
            ],
            attrs: { href: "#" },
            on: {
              click: function($event) {
                return _vm.getFilterValue(item.id)
              }
            }
          },
          [_vm._v("\n            " + _vm._s(item.value) + "\n        ")]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }