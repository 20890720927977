var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item dropdown" }, [
    _c(
      "a",
      {
        staticClass: "d-flex align-items-center nav-link dropdown-toggle",
        attrs: {
          href: "#",
          id: "languageDropdown",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n        " + _vm._s(_vm.selectedLanguage) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-right navbar-dropdown language-dropdown",
        attrs: { "aria-labelledby": "languageDropdown" }
      },
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "dropdown-item",
            attrs: { href: item.url }
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-center"
              },
              [
                _c("h6", { staticClass: "mb-0 font-weight-normal" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }