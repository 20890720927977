var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "input",
    _vm._g(
      {
        class: "form-control " + _vm.data.inputClass,
        attrs: {
          type: "number",
          name: _vm.name,
          id: _vm.inputFieldId,
          required: _vm.data.required,
          placeholder: _vm.data.placeholder,
          readonly: _vm.data.readOnly,
          disabled: _vm.data.disabled
        },
        domProps: { value: _vm.value }
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }