var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "app-widget-with-icon"
        ? _c("app-widget-with-icon", { attrs: { data: _vm.$props } })
        : _vm.type === "app-widget-without-icon"
        ? _c("app-widget-without-icon", { attrs: { data: _vm.$props } })
        : _vm.type === "app-widget-with-circle"
        ? _c("app-widget-with-circle", { attrs: { data: _vm.$props } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }