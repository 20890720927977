var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "textarea",
    _vm._g(
      {
        class: "custom-scrollbar " + _vm.data.inputClass,
        style: "direction:" + _vm.data.textAreaDir,
        attrs: {
          type: _vm.data.type,
          id: _vm.inputFieldId,
          name: _vm.name,
          required: _vm.data.required,
          placeholder: _vm.data.placeholder,
          readonly: _vm.data.readOnly,
          disabled: _vm.data.disabled,
          rows: _vm.data.textAreaRows,
          cols: _vm.data.textAreaCols,
          minlength: _vm.data.minlength,
          maxlength: _vm.data.maxlength,
          spellcheck: _vm.data.textAreaSpellCheck
        },
        domProps: { value: _vm.value }
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }