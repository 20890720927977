var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-filter single-filter" }, [
    _c("div", { staticClass: "dropdown keep-inside-clicks-open" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-filter",
          attrs: {
            type: "button",
            id: "dropdownMenuButton",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [_c("app-icon", { attrs: { name: "layout" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "dropdownMenuButton-manage" }
        },
        [
          _c("div", { staticClass: "btn-dropdown-close d-sm-none" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("manage_columns")) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "back float-right",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeDropDown.apply(null, arguments)
                  }
                }
              },
              [_c("app-icon", { attrs: { name: "x" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-item" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("want_to_manage_datatable")))]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify mb-0 filter-subtitle-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$t(
                      "please_drag_and_drop_your_column_to_reorder_your_table_and_enable_see_option_as_you_want"
                    )
                  ) +
                  "\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "dropdown-item manage-column-options custom-scrollbar pt-0"
            },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    attrs: { tag: "div" },
                    on: {
                      start: function($event) {
                        return _vm.dragstart($event)
                      },
                      end: _vm.dragend,
                      change: _vm.change
                    },
                    model: {
                      value: _vm.list,
                      callback: function($$v) {
                        _vm.list = $$v
                      },
                      expression: "list"
                    }
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                _vm._l(_vm.list, function(element, index) {
                  return !_vm.isUndefined(element.title)
                    ? _c(
                        "div",
                        {
                          key: "manage-column-" + index,
                          staticClass: "row py-2"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-between"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-type" },
                                [
                                  _c("app-icon", { attrs: { name: "menu" } }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(element.title) +
                                      "\n                            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "custom-control border-switch mb-0 mr-3"
                                },
                                [
                                  _c("input", {
                                    staticClass: "border-switch-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "switch-" + index
                                    },
                                    domProps: { checked: element.isVisible },
                                    on: {
                                      click: function($event) {
                                        element.isVisible = !element.isVisible
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass:
                                      "border-switch-control-indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                0
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider d-none d-sm-block" }),
          _vm._v(" "),
          _c("filter-action", {
            on: { apply: _vm.applyColumnSort, clear: _vm.clear }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }