var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-picker-input" }, [
    _c("div", { staticClass: "dropdown keep-inside-clicks-open" }, [
      _c(
        "div",
        {
          staticClass: "input-group",
          class: { disabled: _vm.data.disabled },
          attrs: {
            id: "dropdownMenuTimePicker" + _vm.inputFieldId,
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _c("input", {
            staticClass: "form-control",
            attrs: {
              id: _vm.inputFieldId,
              type: "text",
              autocomplete: "off",
              placeholder: _vm.data.placeholder,
              required: _vm.data.required
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                return _vm.input($event)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "span",
              {
                staticClass: "input-group-text",
                attrs: { id: "basic-addon2" }
              },
              [_c("app-icon", { attrs: { name: "clock" } })],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: {
            "aria-labelledby": "dropdownMenuTimePicker" + _vm.inputFieldId
          }
        },
        [
          _c("div", { staticClass: "hour custom-scrollbar py-2" }, [
            _vm.timeFormat == 12
              ? _c(
                  "ul",
                  { staticClass: "p-0" },
                  _vm._l(_vm.timeFormat, function(n) {
                    return _c(
                      "li",
                      {
                        key: "hour" + n,
                        staticClass: "dropdown-item py-2",
                        class: { selected: _vm.timeChanged && n == _vm.hour },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectHour(n)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(n < 10 ? "0" + n : n) +
                            ":00\n                    "
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "ul",
                  { staticClass: "p-0" },
                  _vm._l(_vm.timeFormat, function(n, index) {
                    return _c(
                      "li",
                      {
                        key: "hour" + index,
                        staticClass: "dropdown-item py-2",
                        class: {
                          selected:
                            _vm.timeChanged &&
                            (n == _vm.hour || (n == 24 && _vm.hour == 0))
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectHour(n)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(n < 10 ? "0" + n : n == 24 ? "00" : n) +
                            ":00\n                    "
                        )
                      ]
                    )
                  }),
                  0
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "minute custom-scrollbar py-2" }, [
            _c(
              "ul",
              { staticClass: "p-0" },
              _vm._l(60, function(n, index) {
                return _c(
                  "li",
                  {
                    key: "min" + n,
                    staticClass: "dropdown-item py-2",
                    class: { selected: _vm.timeChanged && index == _vm.min },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectMinute(index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.hour
                            ? _vm.hour < 10
                              ? "0" + _vm.hour
                              : _vm.hour > _vm.timeFormat
                              ? "00"
                              : _vm.hour
                            : "00"
                        ) +
                        ":" +
                        _vm._s(index < 10 ? "0" + index : index) +
                        "\n                    "
                    )
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm.timeFormat == 12
            ? _c("div", { staticClass: "am-pm custom-scrollbar py-2" }, [
                _c("ul", { staticClass: "p-0" }, [
                  _c("li", { staticClass: "dropdown-item py-2" }, [
                    _c(
                      "label",
                      { staticClass: "customized-radio radio-default" },
                      [
                        _c("input", {
                          staticClass: "radio-inline",
                          attrs: {
                            type: "radio",
                            name:
                              "TimeFormat" + _vm.name + "-" + _vm.inputFieldId,
                            id:
                              "customRadioAm-" +
                              _vm.name +
                              "-" +
                              _vm.inputFieldId
                          },
                          domProps: { checked: _vm.formatTxtAM },
                          on: {
                            click: function($event) {
                              return _vm.selectAmPM("AM")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("am")) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown-item py-2" }, [
                    _c(
                      "label",
                      { staticClass: "customized-radio radio-default" },
                      [
                        _c("input", {
                          staticClass: "radio-inline",
                          attrs: {
                            type: "radio",
                            name:
                              "TimeFormat" + _vm.name + "-" + _vm.inputFieldId,
                            id:
                              "customRadioPm-" +
                              _vm.name +
                              "-" +
                              _vm.inputFieldId
                          },
                          domProps: { checked: !_vm.formatTxtAM },
                          on: {
                            click: function($event) {
                              return _vm.selectAmPM("PM")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("pm")) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "outside" }, [
      _c("span", { staticClass: "inside" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "outside" }, [
      _c("span", { staticClass: "inside" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }