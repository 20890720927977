var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datatable" },
    [
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _vm.options.showCount ||
      _vm.options.showClearFilter ||
      _vm.filterByOptions
        ? _c("div", { staticClass: "my-2 d-flex justify-content-between" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _vm.options.showCount
                ? _c("p", { staticClass: "text-muted mb-0" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.showItemText) +
                        "\n            "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.options.showCount &&
              _vm.options.showClearFilter &&
              _vm.clearFilterVisible
                ? _c("p", { staticClass: "text-muted mb-0 mx-2" }, [
                    _vm._v("-")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.options.showClearFilter && _vm.clearFilterVisible
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.clearAllFilter.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("clear_all_filters")) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.filterByOptions
              ? _c(
                  "div",
                  {
                    staticClass:
                      "dropdown-menu-filter d-flex justify-content-end align-items-center"
                  },
                  [
                    _c("label", { staticClass: "text-muted mr-2 mb-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.filterByOptions.label
                              ? _vm.filterByOptions.label
                              : _vm.$t("sort_by")
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dropdown keep-inside-clicks-open" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-filter",
                            attrs: {
                              type: "button",
                              id: "filter-by-" + _vm.id,
                              "data-toggle": "dropdown"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.filterByValue) +
                                "\n                    "
                            ),
                            _c("img", {
                              staticStyle: { height: "16px", width: "16px" },
                              attrs: {
                                src: _vm.getAppUrl("images/chevron-down.svg"),
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              "my-2 dropdown-menu dropdown-menu-sort-by-" +
                              _vm.id,
                            attrs: { "aria-labelledby": "filter-by-" + _vm.id }
                          },
                          _vm._l(_vm.filterByOptions.options, function(
                            item,
                            index
                          ) {
                            return _c(
                              "a",
                              {
                                key: index,
                                staticClass: "dropdown-item",
                                class: {
                                  "active disabled": item === _vm.filterByValue
                                },
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setFilterBy(item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t(item)) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleBulkSelect
        ? _c(
            "div",
            {
              staticClass:
                "note note-warning shadow animate__animated animate__fadeIn mb-1"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center p-3"
                },
                [
                  !_vm.bulkSelected
                    ? [
                        _c("p", { staticClass: "text-muted mb-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedRows.length +
                                " " +
                                _vm.$t("items_selected")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mx-2 mb-0 text-muted" }, [
                          _vm._v("-")
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectBulkOptions.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("select_all") +
                                    " " +
                                    _vm.totalRow +
                                    " " +
                                    _vm.$t("items")
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    : [
                        _c("p", { staticClass: "text-muted mb-0" }, [
                          _c("span", { staticClass: "text-capitalize" }, [
                            _vm._v(_vm._s(_vm.$t("all")))
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                " " +
                                  _vm.totalRow +
                                  " " +
                                  _vm.$t("items_selected")
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mx-2 mb-0 text-muted" }, [
                          _vm._v("-")
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.clearBulkSelection.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("clear_selection")) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.cardView
        ? _c(
            "div",
            {
              staticClass: "table-responsive custom-scrollbar",
              class: [
                {
                  "table-view-responsive":
                    _vm.options.responsive ||
                    _vm.isUndefined(_vm.options.responsive),
                  "loading-opacity": _vm.preloader,
                  shadow:
                    _vm.options.tableShadow ||
                    _vm.isUndefined(_vm.options.tableShadow)
                },
                _vm.options.tablePaddingClass
                  ? _vm.options.tablePaddingClass
                  : "py-primary"
              ]
            },
            [
              _c("table", { staticClass: "table mb-0" }, [
                _c("thead", [
                  _vm.isUndefined(_vm.options.showHeader) ||
                  _vm.options.showHeader
                    ? _c(
                        "tr",
                        { key: _vm.id + "-table-header" },
                        [
                          !_vm.allColumnDisable && _vm.options.enableRowSelect
                            ? _c(
                                "th",
                                {
                                  key: _vm.id + "-customized-checkbox",
                                  staticClass: "datatable-th pt-0 pr-0"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "customized-checkbox checkbox-default"
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: "row-select-all"
                                        },
                                        domProps: {
                                          checked:
                                            _vm.selectedRows.length ===
                                              _vm.dataSet.length &&
                                            _vm.dataSet.length !== 0
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.doSelectAllRow($event)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "mb-0",
                                        attrs: { for: "row-select-all" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.dataTableColumns, function(column, index) {
                            return !_vm.isEmpty(column) &&
                              (column.isVisible ||
                                _vm.isUndefined(column.isVisible))
                              ? _c(
                                  "th",
                                  {
                                    key: _vm.id + "-header-column-" + index,
                                    staticClass: "datatable-th pt-0",
                                    class: {
                                      "text-right":
                                        column.type === "action" ||
                                        column.titleAlignment === "right"
                                    },
                                    attrs: { "track-by": index }
                                  },
                                  [
                                    column.type === "action" &&
                                    (_vm.isUndefined(_vm.options.showAction) ||
                                      _vm.options.showAction)
                                      ? _c(
                                          "span",
                                          {
                                            key:
                                              _vm.id + "column-action-" + index,
                                            staticClass: "font-size-default"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(column.title) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            key:
                                              _vm.id + "column-normal-" + index,
                                            staticClass: "font-size-default"
                                          },
                                          [
                                            !_vm.isUndefined(column.sortable) &&
                                            column.sortable
                                              ? _c(
                                                  "span",
                                                  {
                                                    key:
                                                      _vm.id +
                                                      "column-normal-sortable-" +
                                                      index,
                                                    staticClass:
                                                      "btn d-flex justify-content-start cursor-pointer font-weight-bold",
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.sortByColumn(
                                                          column,
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(column.title)
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _vm.isSort
                                                          ? _c("sort", {
                                                              key:
                                                                "column-sort-" +
                                                                column.key +
                                                                "-" +
                                                                index,
                                                              attrs: {
                                                                icon:
                                                                  column.sortIcon
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    key:
                                                      _vm.id +
                                                      "column-normal-without-sortable-" +
                                                      index
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(column.title) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                  ]
                                )
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.dataSet, function(row, rowIndex) {
                      return _vm.dataSet.length > 0
                        ? [
                            _c(
                              "tr",
                              [
                                !_vm.allColumnDisable &&
                                _vm.options.enableRowSelect
                                  ? _c(
                                      "td",
                                      { staticClass: "datatable-td pr-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "customized-checkbox checkbox-default"
                                          },
                                          [
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                id: "row-select-" + rowIndex
                                              },
                                              domProps: {
                                                checked: _vm.includesInSelectedRow(
                                                  row
                                                ),
                                                value: row.id
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.doSelectRow(
                                                    $event,
                                                    row
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("label", {
                                              staticClass: "mb-0",
                                              attrs: {
                                                for: "row-select-" + rowIndex
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.columns, function(
                                  column,
                                  columnIndex
                                ) {
                                  var _obj, _obj$1, _obj$2, _obj$3
                                  return !_vm.isEmpty(column) &&
                                    (column.isVisible ||
                                      _vm.isUndefined(column.isVisible))
                                    ? _c(
                                        "td",
                                        {
                                          key:
                                            "column" + rowIndex + columnIndex,
                                          staticClass: "datatable-td",
                                          class:
                                            ((_obj = {
                                              "text-md-right":
                                                column.type === "action",
                                              "pt-0 border-top-0":
                                                _vm.options.showHeader ===
                                                  false && rowIndex === 0
                                            }),
                                            (_obj[
                                              column.tdClassName
                                            ] = !_vm.isUndefined(
                                              column.tdClassName
                                            )),
                                            _obj),
                                          attrs: {
                                            "data-title": [
                                              _vm.options.responsive ||
                                              _vm.isUndefined(
                                                _vm.options.responsive
                                              )
                                                ? column.title
                                                : ""
                                            ]
                                          }
                                        },
                                        [
                                          column.type === "link"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-link-" +
                                                    rowIndex +
                                                    columnIndex,
                                                  class:
                                                    ((_obj$1 = {}),
                                                    (_obj$1[
                                                      column.className
                                                    ] = !_vm.isUndefined(
                                                      column.className
                                                    )),
                                                    _obj$1)
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm.getAppUrl(
                                                          column.modifier(
                                                            row[column.key],
                                                            row
                                                          )
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                " +
                                                          _vm._s(
                                                            row[column.key]
                                                          ) +
                                                          "\n                            "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : column.type === "image"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-image-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  _c("app-avatar", {
                                                    attrs: {
                                                      "avatar-class":
                                                        column.className,
                                                      url: row[column.key]
                                                        ? row[column.key]
                                                        : column.default,
                                                      "alt-text": column.altText
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : column.type === "media-object"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-media-object-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  _c("app-media-object", {
                                                    attrs: {
                                                      "img-url": column.modifier(
                                                        row[column.key],
                                                        row
                                                      )
                                                        ? column.modifier(
                                                            row[column.key],
                                                            row
                                                          )
                                                        : column.default,
                                                      "alt-text":
                                                        row[
                                                          column.mediaTitleKey
                                                        ],
                                                      "media-title":
                                                        row[
                                                          column.mediaTitleKey
                                                        ],
                                                      "media-subtitle":
                                                        row[
                                                          column
                                                            .mediaSubtitleKey
                                                        ]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : column.type === "component"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-component-" +
                                                    rowIndex +
                                                    columnIndex,
                                                  class:
                                                    ((_obj$2 = {}),
                                                    (_obj$2[
                                                      column.className
                                                    ] = !_vm.isUndefined(
                                                      column.className
                                                    )),
                                                    _obj$2)
                                                },
                                                [
                                                  _c(column.componentName, {
                                                    tag: "component",
                                                    attrs: {
                                                      data: column.data,
                                                      value: row[column.key],
                                                      "row-data": row,
                                                      "table-id": _vm.id,
                                                      index: columnIndex
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : column.type === "custom-html"
                                            ? _c("span", {
                                                key:
                                                  "column-custom-html-" +
                                                  rowIndex +
                                                  columnIndex,
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    column.modifier(
                                                      row[column.key],
                                                      row
                                                    )
                                                  )
                                                }
                                              })
                                            : column.type === "custom-class"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-custom-class-" +
                                                    rowIndex +
                                                    columnIndex,
                                                  class: column.modifier(
                                                    row[column.key],
                                                    row
                                                  )
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(row[column.key]) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            : column.type === "object"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-object-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        column.modifier(
                                                          row[column.key],
                                                          row
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            : column.type === "action" &&
                                              !_vm.isUndefined(
                                                _vm.options.actions
                                              ) &&
                                              (_vm.isUndefined(
                                                _vm.options.showAction
                                              ) ||
                                                _vm.options.showAction)
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-action-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  _vm.isUndefined(
                                                    _vm.options.actionType
                                                  ) ||
                                                  _vm.options.actionType ===
                                                    "dropdown"
                                                    ? _c("dropdown-action", {
                                                        key:
                                                          _vm.options
                                                            .actionType +
                                                          columnIndex,
                                                        attrs: {
                                                          actions:
                                                            _vm.options.actions,
                                                          "unique-key":
                                                            row[column.key],
                                                          "row-data": row
                                                        },
                                                        on: {
                                                          action: _vm.getAction
                                                        }
                                                      })
                                                    : _c("default-action", {
                                                        key:
                                                          _vm.options
                                                            .actionType +
                                                          columnIndex,
                                                        attrs: {
                                                          actions:
                                                            _vm.options.actions,
                                                          "unique-key":
                                                            row[column.key],
                                                          "row-data": row
                                                        },
                                                        on: {
                                                          action: _vm.getAction
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            : column.type === "dynamic-content"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-dynamic-content-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  !column.modifier(
                                                    row[column.key],
                                                    row
                                                  ).isValue &&
                                                  !_vm.getDynamicContentValueByKey(
                                                    "dynamicContent" +
                                                      _vm.activePaginationPage +
                                                      rowIndex +
                                                      columnIndex
                                                  )
                                                    ? _c(
                                                        "button",
                                                        {
                                                          class: [
                                                            column.className
                                                              ? column.className
                                                              : "btn btn-primary"
                                                          ],
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.getDynamicContent(
                                                                "dynamicContent" +
                                                                  _vm.activePaginationPage +
                                                                  rowIndex +
                                                                  columnIndex,
                                                                column.modifier(
                                                                  row[
                                                                    column.key
                                                                  ],
                                                                  row
                                                                )
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          column.icon
                                                            ? _c("app-icon", {
                                                                attrs: {
                                                                  name:
                                                                    column.icon
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                " +
                                                              _vm._s(
                                                                column.label
                                                                  ? column.label
                                                                  : ""
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              column.modifier(
                                                                row[column.key],
                                                                row
                                                              ).isValue
                                                                ? column.modifier(
                                                                    row[
                                                                      column.key
                                                                    ],
                                                                    row
                                                                  ).value
                                                                : _vm.getDynamicContentValueByKey(
                                                                    "dynamicContent" +
                                                                      _vm.activePaginationPage +
                                                                      rowIndex +
                                                                      columnIndex
                                                                  )
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ])
                                                ]
                                              )
                                            : column.type === "button"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-button-" +
                                                    rowIndex +
                                                    columnIndex
                                                },
                                                [
                                                  column.modifier(
                                                    row[column.key],
                                                    row
                                                  ) != false
                                                    ? _c(
                                                        "button",
                                                        {
                                                          class: [
                                                            column.className
                                                              ? column.className
                                                              : "btn btn-primary"
                                                          ],
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.getButtonTypeValue(
                                                                row,
                                                                column
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          column.icon
                                                            ? _c("app-icon", {
                                                                attrs: {
                                                                  name:
                                                                    column.icon
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                " +
                                                              _vm._s(
                                                                column.modifier(
                                                                  row[
                                                                    column.key
                                                                  ],
                                                                  row
                                                                )
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            : column.type === "text"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-normal-" +
                                                    rowIndex +
                                                    columnIndex,
                                                  class:
                                                    ((_obj$3 = {}),
                                                    (_obj$3[
                                                      column.className
                                                    ] = !_vm.isUndefined(
                                                      column.className
                                                    )),
                                                    _obj$3)
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(row[column.key]) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            : column.type ===
                                              "expandable-column"
                                            ? _c(
                                                "span",
                                                {
                                                  key:
                                                    "column-expandable-" +
                                                    rowIndex +
                                                    columnIndex,
                                                  staticClass:
                                                    "table-expanded-column"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-flex"
                                                    },
                                                    [
                                                      _vm.expandableColumn.modifier(
                                                        row[
                                                          _vm.expandableColumn
                                                            .key
                                                        ],
                                                        row
                                                      ).prefixData
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "m-0 mr-3 d-flex align-items-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    _vm.expandableColumn.modifier(
                                                                      row[
                                                                        _vm
                                                                          .expandableColumn
                                                                          .key
                                                                      ],
                                                                      row
                                                                    ).prefixData
                                                                  ) +
                                                                  "\n                                "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.expandableColumn.modifier(
                                                        row[
                                                          _vm.expandableColumn
                                                            .key
                                                        ],
                                                        row
                                                      ).visible ||
                                                      _vm.isUndefined(
                                                        _vm.expandableColumn.modifier(
                                                          row[
                                                            _vm.expandableColumn
                                                              .key
                                                          ],
                                                          row
                                                        ).visible
                                                      )
                                                        ? [
                                                            column.modifier(
                                                              row[column.key],
                                                              row
                                                            ).button
                                                              ? [
                                                                  _vm.expandableColumn.modifier(
                                                                    row[
                                                                      _vm
                                                                        .expandableColumn
                                                                        .key
                                                                    ],
                                                                    row
                                                                  ).expandable
                                                                    ? _c(
                                                                        "button",
                                                                        {
                                                                          key: _vm.expandableColumnIcon(
                                                                            rowIndex
                                                                          ),
                                                                          class: [
                                                                            "btn ",
                                                                            "btn-" +
                                                                              _vm.expandableColumnClassName(
                                                                                row,
                                                                                column
                                                                              )
                                                                          ],
                                                                          attrs: {
                                                                            type:
                                                                              "button",
                                                                            "data-toggle":
                                                                              "collapse",
                                                                            "data-target":
                                                                              "#collapse-" +
                                                                              rowIndex,
                                                                            "aria-expanded":
                                                                              "false",
                                                                            "aria-controls":
                                                                              "collapse-" +
                                                                              rowIndex
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              return _vm.expandableButtonClicked(
                                                                                rowIndex
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                            " +
                                                                              _vm._s(
                                                                                _vm.expandableColumnTitle(
                                                                                  rowIndex,
                                                                                  row
                                                                                )
                                                                              ) +
                                                                              "\n                                            "
                                                                          ),
                                                                          _c(
                                                                            "app-icon",
                                                                            {
                                                                              staticClass:
                                                                                "animate__animated animate__fadeIn size-16 ml-2",
                                                                              attrs: {
                                                                                name: _vm.expandableColumnIcon(
                                                                                  rowIndex
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      class: [
                                                                        "badge rounded-pill",
                                                                        "badge-" +
                                                                          _vm.expandableColumnClassName(
                                                                            row,
                                                                            column
                                                                          )
                                                                      ]
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                            " +
                                                                          _vm._s(
                                                                            _vm.expandableColumnTitle(
                                                                              rowIndex,
                                                                              row
                                                                            )
                                                                          ) +
                                                                          "\n                                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.expandableColumn.modifier(
                                                                    row[
                                                                      _vm
                                                                        .expandableColumn
                                                                        .key
                                                                    ],
                                                                    row
                                                                  )
                                                                    .expandable ||
                                                                  _vm.isUndefined(
                                                                    _vm.expandableColumn.modifier(
                                                                      row[
                                                                        _vm
                                                                          .expandableColumn
                                                                          .key
                                                                      ],
                                                                      row
                                                                    ).expandable
                                                                  )
                                                                    ? [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            key: _vm.expandableColumnIcon(
                                                                              rowIndex
                                                                            ),
                                                                            staticClass:
                                                                              "btn p-0 ml-2",
                                                                            attrs: {
                                                                              "data-toggle":
                                                                                "collapse",
                                                                              "data-target":
                                                                                "#collapse-" +
                                                                                rowIndex,
                                                                              "aria-expanded":
                                                                                "false",
                                                                              "aria-controls":
                                                                                "collapse-" +
                                                                                rowIndex
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.expandableButtonClicked(
                                                                                  rowIndex
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "app-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "animate__animated animate__fadeIn size-16",
                                                                                class:
                                                                                  "text-" +
                                                                                  _vm.expandableColumnClassName(
                                                                                    row,
                                                                                    column
                                                                                  ),
                                                                                attrs: {
                                                                                  name: _vm.expandableColumnIcon(
                                                                                    rowIndex
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ]
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            !_vm.isUndefined(_vm.expandableColumn) &&
                            (_vm.expandableColumn.modifier(
                              row[_vm.expandableColumn.key],
                              row
                            ).expandable ||
                              _vm.isUndefined(
                                _vm.expandableColumn.modifier(
                                  row[_vm.expandableColumn.key],
                                  row
                                ).expandable
                              ))
                              ? _c(
                                  "tr",
                                  { staticClass: "table-expandable-area" },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "datatable-td",
                                        attrs: {
                                          colspan: _vm.visibleColumnCount
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "collapse",
                                            attrs: {
                                              id: "collapse-" + rowIndex
                                            }
                                          },
                                          [
                                            _c(
                                              _vm.expandableColumn
                                                .componentName,
                                              {
                                                tag: "component",
                                                attrs: {
                                                  data: row,
                                                  "table-id": _vm.id
                                                }
                                              }
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              (_vm.dataSet.length === 0 && !_vm.preloader) ||
              _vm.allColumnDisable
                ? _c("app-empty-data-block", {
                    attrs: { message: _vm.$t("empty_data_block_dummy_message") }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "position-relative",
              class: { "loading-opacity": _vm.preloader },
              staticStyle: { "min-height": "400px" }
            },
            [
              _c(_vm.options.cardViewComponent, {
                tag: "component",
                attrs: {
                  id: _vm.id,
                  data: _vm.dataSet,
                  actions: _vm.options.actions,
                  "data-loader": _vm.preloader,
                  "filtered-data": _vm.filteredData
                },
                on: { getCardAction: _vm.getAction }
              }),
              _vm._v(" "),
              _vm.dataSet.length === 0 &&
              !_vm.preloader &&
              (_vm.options.cardViewEmptyBlock ||
                _vm.isUndefined(_vm.options.cardViewEmptyBlock))
                ? _c("app-empty-data-block", {
                    attrs: { message: _vm.$t("empty_data_block_dummy_message") }
                  })
                : _vm._e()
            ],
            1
          ),
      _vm._v(" "),
      _c("div", { class: _vm.paginationClass }, [
        _vm.options.paginationType === "pagination" ||
        _vm.isUndefined(_vm.options.paginationType)
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column flex-md-row",
                  class:
                    _vm.options.managePagination ||
                    _vm.isUndefined(_vm.options.managePagination)
                      ? "justify-content-between"
                      : "justify-content-end"
                },
                [
                  _vm.dataSet.length > 0 &&
                  _vm.totalRow > _vm.options.rowLimit &&
                  !_vm.allColumnDisable &&
                  (_vm.options.managePagination ||
                    _vm.isUndefined(_vm.options.managePagination))
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu-filter d-flex align-items-center justify-content-center justify-content-md-start"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropdown keep-inside-clicks-open" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-filter",
                                  attrs: {
                                    type: "button",
                                    id: "show-pagination-" + _vm.id,
                                    "data-toggle": "dropdown"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.paginationRowLimit) +
                                      "\n                            "
                                  ),
                                  _c("img", {
                                    staticStyle: {
                                      height: "16px",
                                      width: "16px"
                                    },
                                    attrs: {
                                      src: _vm.getAppUrl(
                                        "images/chevron-down.svg"
                                      ),
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class:
                                    "my-2 dropdown-menu dropdown-menu-" +
                                    _vm.id,
                                  attrs: {
                                    "aria-labelledby":
                                      "show-pagination-" + _vm.id
                                  }
                                },
                                _vm._l(_vm.showPaginationOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "a",
                                    {
                                      key: index,
                                      staticClass: "dropdown-item",
                                      class: {
                                        "active disabled":
                                          item === _vm.paginationRowLimit
                                      },
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.setPaginationRow(item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("label", { staticClass: "text-muted ml-2 mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("items_showing_per_page")))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("app-pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPagination,
                        expression: "showPagination"
                      }
                    ],
                    attrs: {
                      "total-row": _vm.totalRow,
                      "row-limit": _vm.paginationRowLimit
                    },
                    on: { submit: _vm.paginationSubmit }
                  })
                ],
                1
              )
            ])
          : _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("app-load-more", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPagination,
                      expression: "showPagination"
                    }
                  ],
                  attrs: { preloader: _vm.loadMorePreloader },
                  on: { submit: _vm.loadMoreSubmit }
                })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }