var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-modal",
    {
      attrs: {
        "modal-id": _vm.userAndRoles.users.inviteModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
        "modal-scroll": false
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal }
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.hasData
                  ? "admin/auth/users/attach-roles/" +
                    _vm.userAndRoles.rowData.id
                  : "/admin/auth/users/invite-user"
              }
            },
            [
              !_vm.hasData
                ? _c(
                    "div",
                    { staticClass: "form-group row align-items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "col-sm-3 mb-0",
                          attrs: { for: "userEmail" }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("email")) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("app-input", {
                        staticClass: "col-sm-9",
                        attrs: {
                          id: "userEmail",
                          type: "email",
                          placeholder: _vm.$t("enter_user_email"),
                          required: true
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center mb-0" },
                [
                  _c(
                    "label",
                    { staticClass: "col-sm-3 mb-0", attrs: { for: "roles" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("role")) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "roles",
                      type: "multi-select",
                      list: _vm.roleLists,
                      "list-value-field": "name",
                      isAnimatedDropdown: true,
                      required: true
                    },
                    model: {
                      value: _vm.roles,
                      callback: function($$v) {
                        _vm.roles = $$v
                      },
                      expression: "roles"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }