var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("user_and_roles"),
                icon: "user-check"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("div", { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-with-shadow mr-2",
                attrs: { type: "button", "data-toggle": "modal" },
                on: { click: _vm.operationForUserInvitation }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("invite_users")) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-with-shadow",
                attrs: { type: "button", "data-toggle": "modal" },
                on: {
                  click: function($event) {
                    return _vm.operateRoles(true)
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("add_role")) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mb-4 mb-lg-0 mb-xl-0"
          },
          [
            _c("user", {
              attrs: { data: _vm.userAndRoles.users },
              on: { action: _vm.getActionUser }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7" },
          [
            _c("role", {
              attrs: { data: _vm.userAndRoles.roles },
              on: { action: _vm.getActionRole }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.userAndRoles.users.isInviteModalActive
        ? _c("user-invitation-modal", {
            on: { "close-modal": _vm.closeInviteModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAndRoles.users.isUserModalActive
        ? _c("user-modal", { on: { "close-modal": _vm.closeUserModal } })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAndRoles.users.isManageUsersModalActive
        ? _c("manage-users-modal", {
            on: { "close-modal": _vm.closeManageUserModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAndRoles.roles.isAddEditModalActive
        ? _c("roles-add-edit-modal", {
            attrs: { "selected-url": _vm.selectedUrl, manage: _vm.manage },
            on: { "close-modal": _vm.closeModalRoleAddEditModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmation.isActive
        ? _c("dashboard-delete-modal", {
            attrs: {
              preloader: _vm.deleteLoader,
              "modal-id": _vm.confirmation.modalId
            },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }