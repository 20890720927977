var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6" },
          [
            _c("app-breadcrumb", {
              attrs: {
                "page-title": _vm.$t("application"),
                directory: _vm.$t("lists"),
                icon: "grid"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("div", { staticClass: "float-md-right mb-3 mb-sm-3 mb-md-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-with-shadow",
                attrs: { type: "button", "data-toggle": "modal" },
                on: { click: _vm.openAddEditModal }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("add")) +
                    "\n                "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: _vm.tableId, options: _vm.options },
        on: { action: _vm.getListAction }
      }),
      _vm._v(" "),
      _vm.isAddEditModalActive
        ? _c("app-add-modal", {
            attrs: { "table-id": _vm.tableId, "selected-url": _vm.selectedUrl },
            on: { "close-modal": _vm.closeAddEditModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteConfirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "demo-delete" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }