var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "text"
        ? _c(
            "text-input",
            _vm._g(
              { key: "text", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "email"
        ? _c(
            "email-input",
            _vm._g(
              { key: "email", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "number"
        ? _c(
            "number-input",
            _vm._g(
              { key: "number", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "decimal"
        ? _c(
            "decimal-input",
            _vm._g(
              { key: "decimal", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "password"
        ? _c(
            "password",
            _vm._g(
              {
                key: "password",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "date"
        ? _c("date-picker", {
            key: "date",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "time"
        ? _c("time-picker", {
            key: "time",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "smart-select"
        ? _c("smart-select", {
            key: "smart-select",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "search-select"
        ? _c("search-select", {
            key: "search-select",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "select"
        ? _c(
            "select-input",
            _vm._g(
              { key: "select", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "multi-select"
        ? _c(
            "multi-select",
            _vm._g(
              {
                key: "multi-select",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "multi-create"
        ? _c(
            "multi-create",
            _vm._g(
              {
                key: "multi-create",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "textarea"
        ? _c(
            "text-area",
            _vm._g(
              {
                key: "textarea",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "currency"
        ? _c(
            "currency",
            _vm._g(
              {
                key: "currency",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "radio-buttons"
        ? _c("radio-buttons", {
            key: "radio-buttons",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "radio"
        ? _c(
            "radio",
            _vm._g(
              { key: "radio", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "switch"
        ? _c(
            "switch-input",
            _vm._g(
              { key: "switch", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "single-checkbox"
        ? _c(
            "single-checkbox",
            _vm._g(
              {
                key: "single-checkbox",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "checkbox"
        ? _c("check-box", {
            key: "checkbox",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input, changed: _vm.getEvent }
          })
        : _vm.type === "text-editor"
        ? _c("text-editor", {
            key: "text-editor",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "tel-input"
        ? _c("tel-input", {
            key: "tel-input",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm.type === "file"
        ? _c(
            "file-upload",
            _vm._g(
              { key: "file", attrs: { data: _vm.$props, value: _vm.value } },
              _vm.$listeners
            )
          )
        : _vm.type === "custom-file-upload"
        ? _c(
            "custom-image-file-uploader",
            _vm._g(
              {
                key: "custom-file-upload",
                attrs: { data: _vm.$props, value: _vm.value }
              },
              _vm.$listeners
            )
          )
        : _vm.type === "dropzone"
        ? _c("dropzone-uploader", {
            key: "dropzone",
            attrs: { data: _vm.$props, value: _vm.value },
            on: { input: _vm.input }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isUndefined(_vm.fieldProps.isSubmit) &&
      _vm.fieldProps.isSubmit &&
      !_vm.error.isValid
        ? _c("div", { key: "error" }, [
            _c("small", { staticClass: "text-danger validation-error" }, [
              _vm._v(_vm._s(_vm.error.message))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }