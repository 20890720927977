var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "sidebar sidebar-offcanvas", attrs: { id: "sidebar" } },
    [
      _c(
        "div",
        {
          staticClass:
            "text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
        },
        [
          _c(
            "a",
            {
              staticClass: "navbar-brand brand-logo",
              attrs: { href: _vm.logoUrl }
            },
            [_c("img", { attrs: { src: _vm.logoSrc, alt: "logo" } })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "navbar-brand brand-logo-mini",
              attrs: { href: _vm.logoUrl }
            },
            [_c("img", { attrs: { src: _vm.logoIconSrc, alt: "logo" } })]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav nav-scrolling" },
        _vm._l(_vm.data, function(item, index) {
          return item.permission
            ? _c("li", { key: index, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: item.id ? "#" + item.id : item.url,
                      "data-toggle": item.id ? "collapse" : false,
                      "aria-expanded": item.id ? "false" : false,
                      "aria-controls": item.id ? item.id : false
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-wrapper" },
                      [
                        _c("app-icon", {
                          staticClass: "menu-icon",
                          attrs: { name: item.icon }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-title" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    item.subMenu
                      ? _c("i", { staticClass: "menu-arrow" })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                item.subMenu
                  ? _c(
                      "div",
                      { staticClass: "collapse", attrs: { id: item.id } },
                      [
                        _c(
                          "ul",
                          { staticClass: "nav flex-column sub-menu" },
                          _vm._l(item.subMenu, function(submenuItem, index) {
                            return submenuItem.permission === true ||
                              submenuItem.permission === ""
                              ? _c(
                                  "li",
                                  { key: index, staticClass: "nav-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: { href: submenuItem.url }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(submenuItem.name) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }