var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mx-0 justify-content-center" }, [
    _c(
      "div",
      {
        staticClass:
          "col-11 col-md-10 col-lg-9 col-xl-8 px-primary margin-top-30 margin-bottom-30"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-start mb-primary"
          },
          [
            _c("app-icon", {
              staticClass: "text-secondary size-36",
              attrs: { name: "layers" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ml-3" }, [
              _c("p", { staticClass: "mb-0 font-size-90" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$t("this_purchase_code_is_used") +
                        " " +
                        _vm.usedCount +
                        " " +
                        _vm.$t("times").toLowerCase()
                    )
                )
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.data["purchases"], function(purchase, index) {
          return _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-start mb-primary"
            },
            [
              _c(
                "div",
                { staticClass: "width-150 d-flex align-items-center" },
                [
                  _c("app-icon", {
                    staticClass: "text-info",
                    attrs: { name: "corner-down-right" }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0 text-info" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          index === 0
                            ? _vm.$t("last")
                            : _vm.ordinal(_vm.data["purchases"].length - index)
                        ) +
                        "\n                "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-3 width-350" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("domain") + ": "))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-size-90" }, [
                    _vm._v(_vm._s(purchase.domain_name))
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-0" }, [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("ip") + ": "))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-size-90 text-capitalize" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(purchase.ip ? purchase.ip : "-") +
                        "\n                    "
                    )
                  ])
                ])
              ])
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }